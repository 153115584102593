import React from 'react';
import styled from 'styled-components';
// import Modal from 'react-modal';

const Section = styled.section`
  padding: 2rem;
  background-color: ${({ theme }) => theme.body};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 2rem auto;
  color: ${({ theme }) => theme.text};
`;

const Heading = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.text};
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
`;

const SubHeading = styled.h2`
  margin: 1rem 0;
  color: ${({ theme }) => theme.text};
  font-size: 1.75rem;
`;

const Content = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  background: ${({ theme }) => theme.navBackground};
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.text};
`;

const BudgetList = styled.ul`
  list-style: none;
  padding: 0;
`;

const BudgetItem = styled.li`
  margin: 0.5rem 0;
  color: ${({ theme }) => theme.text};
`;


const generateRandomBudget = () => {
  return [
    { category: 'Team', amount: `$${(Math.random() * 1000000).toFixed(2)}` },
    { category: 'Marketing', amount: `$${(Math.random() * 500000).toFixed(2)}` },
    { category: 'Development', amount: `$${(Math.random() * 750000).toFixed(2)}` },
    { category: 'Operations', amount: `$${(Math.random() * 300000).toFixed(2)}` },
    { category: 'Legal', amount: `$${(Math.random() * 200000).toFixed(2)}` },
    { category: 'Community', amount: `$${(Math.random() * 150000).toFixed(2)}` },
  ];
};

const TokenEconomics = () => {
    const budget = generateRandomBudget();

  return (
    <Section>
      <Heading>Token Economics</Heading>
      <Content>
        <SubHeading>Distribution</SubHeading>
        <p>
          Information about the distribution of YUVA tokens. It could be subdivided
          into various categories like Founders, Advisors, Public, Private Investors,
          etc.
        </p>
      </Content>
      <Content>
        <SubHeading>Use Cases</SubHeading>
        <p><strong>Staking System:</strong> Users can earn interest by staking (locking) their coins for a certain period of time. Staking coins are used to increase network security and verify transactions.</p>
        <p><strong>Yield Farming:</strong> Users can earn interest and reward coins after a certain period of time by providing liquidity to various liquidity pools. This system is very popular in DeFi (Decentralized Finance) projects.</p>
        <p><strong>Lending and Borrowing:</strong> Users can earn interest by lending their coins to other users. At the same time, users can borrow coins and pay a certain interest rate in the process.</p>
        <p><strong>NFT Market:</strong> Users can buy and sell digital artworks, collectibles, and other assets through the Non-Fungible Token (NFT) market. NFTs can provide users with additional earning opportunities.</p>
        <p><strong>Gamification:</strong> Users can earn rewards by completing tasks in various games or competitions. This system keeps users active and provides additional income.</p>
        <p><strong>Referral Program:</strong> Users can earn rewards by inviting new users to the platform and from their activities. This system is effective for expanding the user base.</p>
        <p><strong>Liquidity Providing:</strong> Users can earn a share of transaction fees by contributing to liquidity pools. This provides extra income for those who contribute to the liquidity provision process.</p>
        <p><strong>Profit Sharing:</strong> A portion of the profits obtained by the platform can be distributed to coin owners. This allows users to directly benefit from the success of the platform.</p>
        <p><strong>Education and Consulting Services:</strong> Users can earn coins in return for these services by receiving education and consulting services about cryptocurrencies and blockchain technology. They can gain knowledge and earn by participating in educational programs.</p>
      </Content>
      <Content>
        <SubHeading>Budget Allocation</SubHeading>
        <BudgetList>
          {budget.map((item, index) => (
            <BudgetItem key={index}>
              <strong>{item.category}:</strong> {item.amount}
            </BudgetItem>
          ))}
        </BudgetList>
      </Content>
    </Section>
  );
};

export default TokenEconomics;
