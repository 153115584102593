// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, lightTheme, darkTheme } from './styles/themes';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './pages/Home';
import TokenEconomics from './pages/TokenEconomics';
// import Charts from './pages/Charts';
import TimeLine from './pages/Timeline';
//
const App = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  
  const themeToggle = () => {
    if (theme === 'light') {
      localStorage.setItem('theme', 'dark');
    } else {
      localStorage.setItem('theme', 'light');
    }
    setTheme(theme === 'light' ? 'dark' : 'light');
  };
  
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <Router>
        <NavBar themeToggle={themeToggle} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/timeline' element={<TimeLine />} />
          <Route path="/token-economics" element={<TokenEconomics />} />
          {/* <Route path="/charts" element={<Charts />} /> */}
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
