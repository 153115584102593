import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FiCopy, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Section = styled.section`
  padding: 2rem;
  text-align: center;
`;

const Text = styled.h1`
  font-size: 2rem;
  margin-right: 10px; /* Add space between "Welcome to" and changing text */
  position: relative;
  display: inline-block;
`;

const AddressBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 60%;
  margin: 0 20vh;
`;

const ChainIcon = styled.img`
  width: 30px;
  height: auto;
`;

const ChainName = styled.span`
  font-weight: bold;
  padding: 5px 10px;
  flex: 1;
  text-align: end;
`;

const ContractAddress = styled.input`
  flex: 1;
  padding: 5px;
  margin-left: 10px; /* Add margin to separate from chain name */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: inherit;
  text-align: end;
`;

const CopyButton = styled.button`
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Cursor = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 2px;
  height: 1.2em;
  background-color: black;
  animation: ${keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `} 0.5s infinite alternate;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const FAQSection = styled.div`
  margin-top: 50px;
`;

const Answer = styled.p`
  font-size: 0.9rem;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${styled.theme === 'dark' ? '#333' : '#f8f9fa'};
  color: ${styled.theme === 'dark' ? '#fff' : '#000'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const FAQ = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 20px;
`;

const Question = styled.h3`
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DropIcon = styled.span`
  display: inline-block;
  margin-left: 10px;
  transition: transform 0.3s;

  ${({ isOpen }) =>
    isOpen &&
    `
    transform: rotate(180deg);
  `}
`;

const Home = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [visibleAnswerIndex, setVisibleAnswerIndex] = useState(-1);
  const [faqItems, setFaqItems] = useState([]);
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    const words = ['YUVA', 'METAVERSE', 'FUTURE'];
    // Typing effect and Delete Typing effect
    // Each word will be changed after completion of typing then 4 sec delay and then deleting
    // Deleting needs to be done before changing the word
    // After deleting the word, there will be 1 second timeout and then the next word will be displayed
    const interval = setInterval(() => {
      const currentText = displayedText;
      const currentWord = words[currentWordIndex];
      if (!isDeleting) {
        if (currentText.length < currentWord.length) {
          setDisplayedText(currentWord.substring(0, currentText.length + 1));
        } else {
          setIsDeleting(true);
        }
      } else {
        if (currentText.length > 0) {
          setDisplayedText(currentText.substring(0, currentText.length - 1));
        } else {
          setIsDeleting(false);
          setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }
      }
    }, isDeleting ? 50 : 150);
    return () => clearInterval(interval);
  }, [displayedText, isDeleting, currentWordIndex]);


  
  useEffect(() => {
    // Fetch FAQ items from JSON file
    fetch('https://raw.githubusercontent.com/yuvatoken/.github/main/FAQs.json')
      .then((response) => response.json())
      .then((data) => setFaqItems(data))
      .catch((error) => console.error('Error fetching FAQ items:', error));

    // Fetch contracts from JSON file
    fetch('https://raw.githubusercontent.com/yuvatoken/.github/main/contracts.json')
      .then((response) => response.json())
      .then((data) => {
        setContracts(data.contracts);
      })
      .catch((error) => console.error('Error fetching contracts:', error));

  }, []);

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address);
    if (address === "Soon...") {
      toast.error('Contract address not available yet!', {
        position: 'top-right',
        autoClose: 2000, // Close the notification after 2 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    toast.success('Address copied to clipboard!', {
      position: 'top-right',
      autoClose: 2000, // Close the notification after 2 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleToggleAnswer = (index) => {
    setVisibleAnswerIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <Section>
      <ToastContainer />
      <Text>
        Welcome to {displayedText}
        <Cursor />
      </Text>
      <p>Make life easier for everyone.</p>
      {contracts.map((contract, index) => (
        <AddressBox key={index}>
          <ChainName>{contract.chainName}</ChainName>
          <ChainIcon src={contract.chainIcon} alt="Chain Icon" />
          {contract.contractAddress === "Soon..." ? (
            <ContractAddress value="Soon..." readOnly />
          ) : (
            <ContractAddress value={contract.contractAddress} readOnly />
          )}

          <CopyButton
            onClick={() => handleCopyAddress(contract.contractAddress)}
            disabled={contract.contractAddress === "Soon..."}
          >
            <FiCopy />
          </CopyButton>
        </AddressBox>
      ))}
      <FAQSection>
        {faqItems.map((item, index) => (
          <FAQ key={index}>
            <Question onClick={() => handleToggleAnswer(index)}>
              {item.question}
              <DropIcon>
                {visibleAnswerIndex === index ? <FiChevronUp /> : <FiChevronDown />}
              </DropIcon>
            </Question>
            <Answer isVisible={visibleAnswerIndex === index}>
              {item.answer}
            </Answer>
          </FAQ>
        ))}
      </FAQSection>
    </Section>
  );
};

export default Home;
