import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaFacebook, FaLinkedin, FaGithub } from 'react-icons/fa';


const FooterContainer = styled.footer`
  background: #333;
  color: #fff;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #fff;
  position: relative;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;

  a {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: #666;
    }
  }
`;

const Copyright = styled.p`
  margin: 0;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <SocialIcons>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
        <a href="https://github.com" target="_blank" rel="noopener noreferrer">
          <FaGithub />
        </a>
      </SocialIcons>
      <Copyright>
        &copy; {new Date().getFullYear()} YUVA. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
