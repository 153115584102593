import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const commonStyles = css`
  font-family: 'Chivo', sans-serif;
  letter-spacing: 0.5px;
  font-weight: 100;
  font-size: 1.03em;
`;

const TimelineWrapper = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const TimelineTitle = styled.h1`
  font-family: 'Saira', sans-serif;
  letter-spacing: 1.5px;
  font-weight: 100;
  font-size: 1em;
  color: gray;
`;

const TimelineList = styled.ul`
  border-left: 4px solid;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  ${({ theme }) => css`
    color: ${theme.text};
    background: ${theme.body};
    border-left-color: ${theme === 'dark' ? '#004ffc' : '#6a0dad'};
  `}
  ${commonStyles}
  margin: 50px auto;
  position: relative;
  line-height: 1.4em;
  padding: 50px;
  list-style: none;
  text-align: left;
  max-width: 30%;
`;

const TimelineEvent = styled.li`
  border-bottom: 1px dashed rgb(224, 21, 21, 0.5);
  padding-bottom: 25px;
  margin-bottom: 50px;
  position: relative;
  ${({ isFuture, theme }) => isFuture && css`
  background: linear-gradient(90deg
    ,${theme === 'dark' ? '#004ffc' : '#6a0dad'}
    ,${theme === 'dark' ? '#00ffaa' : '#ff00ff'}
    ,${theme === 'dark' ? '#004ffc' : '#6a0dad'}
  );
  background-size: 200%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: shine 5s linear infinite;
  @keyframes shine {
    to {
      background-position: 200%;
    }
  }
`}

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
`;

const EventDate = styled.div`
  color: ${({ theme }) => (theme === 'dark' ? 'rgb(0 255 170 / 96%);' : '#6a0dad')};
  font-weight: 100;
  font-size: 0.9em;
  font-family: 'Saira', sans-serif;
`;

const MoreInfoButton = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.text};
  margin-top: 10px;
  text-decoration: none;
  font-size: 0.8em;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.body};
  border: 1px solid ${({ theme }) => theme.text};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.body};
  }
`;

const Timeline = ({ theme }) => {
  const [events, setEvents] = useState([]);
  const [timeZone, setTimeZone] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://raw.githubusercontent.com/yuvatoken/.github/main/timeline.json');
        const data = await response.json();
        setEvents(data.timeline);
        setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
      } catch (error) {
        console.error('Error fetching the timeline data:', error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (timestamp) => {
    return new Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    }).format(new Date(timestamp * 1000));
  };

  return (
    <TimelineWrapper id="timeline-content">
      <TimelineTitle>Time zone: {timeZone}</TimelineTitle>
      <TimelineList className="timeline">
        {events.map((event, index) => (
          <TimelineEvent className="event" key={index} isFuture={event.labels && event.labels.includes('future')}>
            <h3>{event.title}</h3>
            {event.timestamp && <EventDate>{formatDate(event.timestamp)}</EventDate>}
            <p>{event.description}</p>
            {event.more_info && (
              <MoreInfoButton href={event.more_info} target="_blank" rel="noopener noreferrer">
                More Info <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '5px' }} />
              </MoreInfoButton>
            )}
          </TimelineEvent>
        ))}
      </TimelineList>
    </TimelineWrapper>
  );
};

export default Timeline;
