import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo512.png';

const NavContainer = styled.nav`
  background: ${({ theme }) => theme.navBackground};
  color: ${({ theme }) => theme.text};
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.text};
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1rem;
`;

const Logo = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5rem;
  color: ${({ theme, isCurrent }) => (isCurrent ? theme.highlight : theme.text)};
  text-shadow: ${({ isCurrent }) => (isCurrent ? '2px 2px 4px rgba(0, 0, 0, 0.5)' : 'none')};
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  margin-right: 0.5rem;
  width: 1.5rem;
  height: auto;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme, isCurrent }) => (isCurrent ? theme.highlight : theme.text)};
  font-weight: ${({ isCurrent }) => (isCurrent ? 'bold' : 'normal')};
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.highlight};
  }
`;

const ThemeToggle = styled.button`
  background: ${({ theme }) => theme.navBackground};
  color: ${({ theme }) => theme.text};
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 1.5rem;

  &:hover {
    color: ${({ theme }) => theme.highlight};
  }
`;

const NavBar = ({ themeToggle, currentTheme }) => {
  const { pathname } = useLocation();
  const [icon, setIcon] = React.useState(faSun);

  useEffect(() => {
    setIcon(currentTheme === 'light' ? faSun : faMoon);
  }, [currentTheme]);

  return (
    <NavContainer>
      <Logo to="/" isCurrent={pathname === '/'}>
        <LogoImage src={logo} alt="Logo" />
        YUVA
      </Logo>
      <NavLinks>
        <NavLink to="/token-economics" isCurrent={pathname === '/token-economics'}>
          Token Economics
        </NavLink>
        <NavLink to="/timeline" isCurrent={pathname === '/timeline'}>
          Timeline
        </NavLink>
      </NavLinks>
      <ThemeToggle onClick={themeToggle}>
        <FontAwesomeIcon icon={icon} />
      </ThemeToggle>
    </NavContainer>
  );
};

export default NavBar;
