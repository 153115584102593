
import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
  body: '#FFF',
  text: '#000',
  navBackground: '#FFF',
};

export const darkTheme = {
  body: '#000',
  text: '#FFF',
  navBackground: '#333',
};

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
  }
  a {
    color: ${({ theme }) => theme.text};
    text-decoration: none;
  }
  button {
    background: ${({ theme }) => theme.navBackground};
    color: ${({ theme }) => theme.text};
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
`;